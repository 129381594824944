var Brand = {
    initReadmore: function(target){
        $(target).readmore({
            embedCSS: false,
            speed: 500,
            collapsedHeight: 60,
            moreLink: '<a class="readmore-btn readmore" href="#">Voir plus<br><i class="fa fa-caret-down" aria-hidden="true"></i></a>',
            lessLink: '<a class="readmore-btn readless" href="#">Voir moins <br><i class="fa fa-caret-up" aria-hidden="true"></i></a>'
        });
    },
};