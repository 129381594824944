var InfiniteScroll = {
    init: function(container)
    {
        InfiniteScroll.initScroll(container);
    },
    initScroll: function(container)
    {
        InfiniteScroll.ajaxready = true;
        $(window).scroll(function() {
            if (!InfiniteScroll.ajaxready) return;
            if(/*($(window).scrollTop() + $(window).height()) == $(document).height()
                || agentID &&*/ ($(window).scrollTop() + $(window).height()) + 1000 > $(document).height()) {
                InfiniteScroll.ajaxready = false;
                if($(container).find('.infinite-next-page-link').length > 0){
                    InfiniteScroll.setLoader(container);
                    var url = $(container).find('.infinite-next-page-link').attr('href');
                  $(container).find('.infinite-next-page-link').remove();
                    $.ajax({
                        url: url,
                        type: 'GET'
                    }).done(function(data){
                        $(container).find('#loader').before(data);
                        InfiniteScroll.removeLoader(container);
                      InfiniteScroll.ajaxready = true;
                    });
                }
            }
        });
    },
    setLoader: function (container)
    {
        $(container).find('#loader').fadeIn(400);
    },
    removeLoader: function(container){
        $(container).find('#loader').fadeOut(400);
    },
    getNewPage: function()
    {

    }
};
