var Search = {
    el : $('#search-panel'),
    content: $('#search-panel .search-panel-content'),
    searchInput: $('#search-panel .search-container-wrapper .search-term'),
    xhr : null,
    initSearch: function(){
        var self = this;
        if(!this.el.hasClass('initiated')){
            this.el.addClass('initiated');
            $('.search-container-wrapper .search-link').on('click', function(){
                $('.search-container-wrapper').addClass('focused');
                $('.search-container-wrapper .search-term').focus();
                if($('.search-container-wrapper .search-term').val() != ''){
                    $('.search-suggest').empty();
                }

            });
            $('.search-container-wrapper .search-term').on('focusout', function(){
                if($('.search-container-wrapper .search-term').val() == ""){
                    $('.search-suggest').html($('.search-suggest').data('text'));
                    self.resetSearch();
                }
            });

            // remove suggest
            $('.search-container-wrapper .search-term').on('input',function(){
                $('.search-suggest').empty();
            });
            //ajax search
            $('.search-container-wrapper .search-term').on('input',function(){
                var searchTerm = $(this).val();
                if(searchTerm !== "" && searchTerm !== " ") {
                    self.process(searchTerm);
                } else {
                    self.resetSearch();
                }
            });

            $('.search-container-wrapper .search-term').on('focusin', function(){
                $('.search-suggest').empty();
            });
        }
    },
    resizeSearch: function(){
        this.el.css('min-height',$(document).height()-10);
    },
    resetSearch: function(){
        $('.search-container-wrapper .search-term').val('');
        $('.search-suggest').html($('.search-suggest').data('text'));
        this.clearContent();
        this.resizeSearch();
    },
    togglePanel: function(){
        if(this.panelRevealed()){
            this.hideSearchPanel();
            this.resetSearch();
        } else {
            this.initSearch();
            this.resizeSearch();
            this.showSearchPanel();
        }
    },
    panelRevealed : function(){
        return !this.el.hasClass('hide');
    },
    showSearchPanel: function(){
        $(window).scrollTop(0);
        this.el.removeClass('hide');
    },
    hideSearchPanel: function(){
        this.el.addClass('hide');
    },
    process: function(searchTerm){
        var self = this;
        this.clearContent();
        this.showWaitingLayer(this.content);
        var url = this.el.data('url');
        var data = { 'phrase' : searchTerm };

        self.xhr = $.ajax({
            url: url,
            type: 'GET',
            data: data,
            beforeSend : function(){
                if(self.xhr !== null) {
                    self.xhr.abort();
                }
            }
        }).done(function(data){
            self.content.html(data);
            $('.lazy').Lazy({
                // your configuration goes here
                scrollDirection: 'vertical',
                effect: 'fadeIn',
                visibleOnly: true,
                onError: function (element) {
                    console.log('error loading ' + element.data('src'));
                }
            });
            $("#search-panel").find('.flash-countdown-wrapper').each(function(){
                Countdown.init(this);
            });

          $('.catalog-swiper-container').each(function(index,val){
            if(!$(val).hasClass('swip-initiated')){
              $(val).addClass('swip-initiated');
              AppSwiper.init('#'+$(val).attr('id'),{
                // Optional parameters
                direction: 'horizontal',
                loop: true,
                effect: 'fade',

                // If we need pagination
                pagination: {
                  el: '.swiper-pagination'
                },
                // Navigation arrows
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
                },
              });
            }
          });

            /*$(container).find('#loader').before(data);
            InfiniteScroll.removeLoader(container);
            $(window).data('ajaxready', true);*/
        });
    },

    clearContent: function(){
        this.content.empty();

        this.removeWaitingLayer();
    },
    showWaitingLayer: function(aim){
        var html = '<div class="waiting-layer" style="position:absolute; text-align:center; top:100px; left:0px;width:100%;">'+
            this.getWaitingSvg()+
            '</div>';
        aim.append(html);
    },
    getWaitingSvg: function(){
        return '<svg width="50px"  height="50px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="lds-eclipse" style="background: none;"><path ng-attr-d="{{config.pathCmd}}" ng-attr-fill="{{config.color}}" stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="white" transform="rotate(144 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path></svg>';

    },
    removeWaitingLayer: function(){
        $('.waiting-layer').remove();
    }
};
