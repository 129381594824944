var Wishlist = {
    triggerLike: function(element){
        Wishlist.preEdit(element);
        if($(element).find('.likeicon').hasClass('liked')){
            var $url = $(element).data('ajax');

        } else {
            var $url = $(element).data('remove');
        }

        $.ajax({
            url: $url,
            type: 'GET',
            success: function(data,response){
                var type = data['type'];
                switch(type){
                    case 1:
                        Wishlist.changeLiked(element);
                        break;
                    case 0:
                        Wishlist.changeUnliked(element);
                        break;
                    default:
                        break;
                }
                Wishlist.editCount(element,data['count']);
            },
            error: function(data,response){
                Wishlist.preEdit(element);
            }
        });
    },
    preEdit: function(element){
        var count = parseInt($(element).parent().find('.product-likes-count').html());
        if($(element).find('.likeicon').hasClass('liked')){
            Wishlist.changeUnliked(element);
            count = count - 1;
        } else {
            Wishlist.changeLiked(element);
            count = count + 1;
        }
        Wishlist.editCount(element,count);
    },
    editCount: function(element,count){
        $(element).parent().find('.product-likes-count').html(count);
    },
    changeLiked: function(element,count){
        $(element).html('<i class="fas fa-heart liked likeicon"></i>');
    },
    changeUnliked: function(element){
        $(element).html('<i class="far fa-heart likeicon"></i>');
    }

}