

var Cart = {
    ajaxCartDelete : function(element){
        if(!$(element).hasClass('cart-ui-disabled')) {
            Cart.disableCartUi();
            Cart.addWaitingLayer();
            var $data = {
                _csrf_token: $(element).data('csrf-token')
            };
            $.ajax({
                url: $(element).data('action'),
                type: "DELETE",
                data: $data,
                success: function (response) {
                    Cart.refreshCart(response);
                },
                error: function(error){
                    Cart.triggerError()
                }

            });
        }
    },
    triggerError: function(){
        Cart.enableCartUi();
        Cart.removeWaitingLayer();
    },
    disableCartUi: function() {
        $('.cart-ui').each(function () {
            $(this).addClass('cart-ui-disabled');
        });
    },
    enableCartUi: function(){
        $('.cart-ui').each(function () {
            $(this).removeClass('cart-ui-disabled');
        });
    },
    ajaxCartAdd : function(object){
        if(!$(object).hasClass('cart-ui-disabled')){
            Cart.disableCartUi();
            Cart.addWaitingLayer();
            var $input = $(object).parent().find('input');
            var $qtyShowLabel= $(object).parent().find('.qty-unit');
            var $qty = parseInt($input.val());
            var $newqty = $qty+1;
            $input.val($newqty);
            $qtyShowLabel.html($newqty);
            if($newqty > 0){
                $input.val($newqty);
                $qtyShowLabel.html($newqty);
            }
            var successCallback = function () {

            }
            var errorCallback = function () {
                $input.val($qty);
                $qtyShowLabel.html($qty);
            }
            Cart.saveCart(successCallback,errorCallback);

        }
    },

    ajaxCartRemove : function(object){
        if(!$(object).hasClass('cart-ui-disabled')) {
            Cart.disableCartUi();
            Cart.addWaitingLayer();
            var $input = $(object).parent().find('input');
            var $qtyShowLabel = $(object).parent().find('.qty-unit');
            var $qty = parseInt($input.val());
            var $newqty = $qty - 1;
            if ($newqty > 0) {
                $input.val($newqty);
                $qtyShowLabel.html($newqty);
            }
            var successCallback = function () {

            }
            var errorCallback = function () {

            }
            Cart.saveCart(successCallback,errorCallback);
        }
    },

    refreshCart: function(response){
        var template = response.template;
        if(response.length > 0 ){
            $('.cart-length').show();
            $('.cart-length').html(response.length);
        } else {
            $('.cart-length').hide();
        }
        if(response.template){
            $('.shop-header .cell-right-inner-content  .cart.popup').empty();
            $('.shop-header .cell-right-inner-content  .cart.popup').append(response.template);
        }
        if( typeof response.message !== 'undefined' && response.message !== ''){
            UiMessage.showMessage('#cart-widget',response.message);
        }
        //Cart.initCartPopup();
        //Cart.showCartPopup();
    },

    addWaitingLayer: function(){
        var $html = '<div class="ui segment cart-waiting-layer">'+
            '<div class="ui active inverted dimmer">'+
            '<div class="ui loader"></div>'+
            '</div>'+
            '<p></p>'+
            '</div>';

        $('#cart-inner-wrapper').prepend($html);
    },

    removeWaitingLayer: function(){
        $('.cart-waiting-layer').remove();
    },

    initCartPopup: function(){
        var self = this;
        $('.cart.button')
            .popup({
                popup: $('.cart.popup'),
                on: 'click',
                lastResort: 'bottom right',
                boundary : $(window),
                onShow: function(){
                    self.resizeCart();
                }
            })
        ;

    },

    showCartPopup: function(){
        this.resizeCart();

        if($('.cart.button').popup('is hidden')){
            $('.cart.button').trigger('click');
        }
    },

    resizeCart: function(){
        var cartInnerWrapper = $('#cart-wrapper #cart-content .cart.popup #cart-inner-wrapper');
        var cartContent = $("#cart-wrapper #cart-content .cart.popup #cart-inner-wrapper .cart-content");
        cartInnerWrapper.css('max-width',$(window).width());
        cartContent.css('max-height',$(window).height()-300);
    },

    saveCart: function(successCallback,errorCallback){
        event.preventDefault();
        event.stopPropagation();

        var $this = $('#cart-widget');

        var jqxhr = $.post($this.attr('action'), $this.serialize(),function(response,code){
            Cart.refreshCart(response);
            successCallback();
        },'JSON');
        jqxhr.fail(function(response,code){
            Cart.triggerError();
            errorCallback();
        });

    }
};

