var NewsletterWidget = {
    progressBar : ' #progress-bar',
    errorbar : ' #error-bar',
    successbar: ' #success-bar',
    steps: {
        0 : {
            'class':'step-0',
            'input-class': '.firstname-input',
            'checkinput' : function(element){
                if($(element).val()){
                    return true;
                } else {
                    return false;
                }
            }
        },
        1 : {
            'class':'step-1',
            'input-class': '.lastname-input',
            'checkinput' : function(element){
                if($(element).val()){
                    return true;
                } else {
                    return false;
                }
            }
        },
        2 : {
            'class':'step-2',
            'input-class': '.email-input',
            'checkinput' : function(element){
                var email = $(element).val();
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email.toLowerCase());
            }
        }
    },
    submitForm : function(element){
        var $widgetId = this.getWidgetId(element);
        if(parseInt($($widgetId).attr('data-step')) <= parseInt($(this.steps).length)){
            this.nextStep(element);
        } else {
            this.subscribe(element);
        }
    },
    subscribe : function(element){
        var $widgetId = this.getWidgetId(element);


        var currStep = parseInt($($widgetId).attr('data-step'));
        var nextStep = currStep + 1;
        var currElement = $($widgetId).find('form '+this.steps[currStep]['input-class']);
        var self = this;
        if($($widgetId).find('form '+this.steps[currStep]['input-class']).val() && this.steps[currStep]['checkinput'](currElement)){
            this.processSubcribe(element);
        } else {
            this.processError(element);
        }
    },
    processSubcribe : function(element){

        var $widgetId = this.getWidgetId(element);

        this.hideErrorBar(element);
        var self = this;


        var $this = $($widgetId).find('form');

        var jqxhr = $.post($this.attr('action'), $this.serialize(),function(response,code){
            self.showSuccessMessage(element,response.message);
        },'JSON');

        jqxhr.fail(function(error){
            self.cleanErrorBar(element);
            var errmsg = error.responseJSON.message;
            $($widgetId).find('form '+self.steps[parseInt($(self.steps).length)]['input-class']).addClass('error');
            self.showErrorBar(element,errmsg);
        });

    },
    getWidgetId : function(element){
        return $(element).data('widget-id');
    },
    nextStep : function(element){
        var $widgetId = this.getWidgetId(element);

        var currStep = parseInt($($widgetId).attr('data-step'));
        var nextStep = currStep + 1;
        var currElement = $($widgetId).find('form '+this.steps[currStep]['input-class']);

        if($($widgetId).find('form '+this.steps[currStep]['input-class']).val() && this.steps[currStep]['checkinput'](currElement)){
            $($widgetId+this.progressBar).removeClass(this.steps[currStep]['class']);
            $($widgetId+this.progressBar).addClass(this.steps[nextStep]['class']);
            $($widgetId).attr('data-step', nextStep);
            $($widgetId).find('form input').addClass('hide');
            $($widgetId).find('form '+this.steps[nextStep]['input-class']).removeClass('hide');
            $($widgetId).find('form '+this.steps[nextStep]['input-class']).focus();
            this.cleanForm(element);
        } else {
            this.processError(element);
        }

    },
    cleanForm: function(element){
        var $widgetId = this.getWidgetId(element);

        $($widgetId).find('form input').removeClass('error');
        this.hideErrorBar();
    },
    processError: function(element){
        var $widgetId = this.getWidgetId(element);

        var currStep = parseInt($($widgetId).attr('data-step'));
        var nextStep = currStep + 1;
        this.cleanErrorBar(element);

        var errmsg = $($widgetId).find('form '+this.steps[currStep]['input-class']).attr('data-input-error');
        $($widgetId).find('form '+this.steps[currStep]['input-class']).addClass('error');
        this.showErrorBar(element,errmsg);
    },
    cleanErrorBar : function(element){
        var $widgetId = this.getWidgetId(element);

        $($widgetId+this.errorbar).empty();
    },
    showErrorBar : function(element,msg){
        var $widgetId = this.getWidgetId(element);

        $($widgetId+this.errorbar).html(msg);
        $($widgetId+this.errorbar).removeClass('hide');
    },
    hideErrorBar : function(element){
        var $widgetId = this.getWidgetId(element);

        this.cleanErrorBar(element);
        $($widgetId+this.errorbar).addClass('hide');
    },
    showSuccessMessage: function(element,msg){

        this.showSuccessBar(element,msg);
    },
    showSuccessBar: function(element,msg){
        var $widgetId = this.getWidgetId(element);

        $($widgetId+this.successbar).empty();
        $($widgetId+this.successbar).html(msg);
        $($widgetId+this.successbar).addClass('success');
    },
    hideSuccessBar: function(element){
        var $widgetId = this.getWidgetId(element);

        $($widgetId+this.successbar).removeClass('success');
    }
};
