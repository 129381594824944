var Instashop = {
    showPost: function(element){
        Modal.showWaitingLayer();
        Modal.showModal();
        $.ajax({
            url : $(element).attr('href'),
            type: 'GET',
            success: function(data){
                Modal.loadContent(data);
                AppSwiper.init('#instashop-product-slider', {
                    // Optional parameters
                    slidesPerView: 3,
                    slidesPerColumn: 1,
                    spaceBetween: 10,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    breakpoints: {
                        1024: {
                            slidesPerView: 2,
                            slidesPerColumn: 1,
                        },
                        768: {
                            slidesPerView: 2,
                            slidesPerColumn: 1,
                        },
                        640: {
                            slidesPerView: 2,
                            slidesPerColumn: 1,
                        }
                    }
                });
                Modal.displayContent();
            },
            error: function(data){
                Modal.closeModal();
            }
        });
    },
    showInstapostProduct: function(element){
        Modal.showWaitingLayer();
        Modal.ajaxLoad($(element).attr('href')+" .product-content-wrapper",function(){
            Modal.displayContent();
            Product.initProductPage(true);
            Modal.showModal();
            $('#page-modal').foundation();
        });
    }
}