var Countdown = {
    init: function(element){
        setInterval(function(){
            var startDate = new Date();
            var NoOfDays = 30;
            startDate.setDate(startDate.getDate() + NoOfDays);

            var years = parseInt($(element).data("end-years"));
            var minutes = parseInt($(element).data("end-minutes"));
            var days = parseInt($(element).data("end-days"));
            var hours = parseInt($(element).data("end-hours"));
            var months = parseInt($(element).data("end-months"));
            var seconds = parseInt($(element).data("end-seconds"));

            var endDate = new Date(years,months,days,0,0,0,0).getTime();

            var timeRemaining = parseInt((endDate/ 1000 - startDate/ 1000));

            if (timeRemaining >= 0) {
                days = parseInt(timeRemaining / 86400);
                timeRemaining = (timeRemaining % 86400);

                hours = parseInt(timeRemaining / 3600);
                timeRemaining = (timeRemaining % 3600);

                minutes = parseInt(timeRemaining / 60);
                timeRemaining = (timeRemaining % 60);

                seconds = parseInt(timeRemaining);

                $(element).find(".days").html(parseInt(days, 10));
                $(element).find(".hours").html(("0" + hours).slice(-2));
                $(element).find(".minutes").html(("0" + minutes).slice(-2));
                $(element).find(".seconds").html(("0" + seconds).slice(-2));
            } else {
                return;
            }
        }, 1000);
    }
};