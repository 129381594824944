var Login = {
    showLoginModal : function () {
        if($('#account-inner-wrapper').length > 0){
            Modal.setTinySize();
            Modal.showWaitingLayer()
            Modal.showModal();
            var content = $('#account-inner-wrapper').html();
            Modal.loadContent(content);
            Modal.hideWaitingLayer();
            $('form.loadable button').on('click', function() {
                return $(this).closest('form').addClass('loading');
            });
            $('.ui.checkbox').checkbox();
        }
    }
}