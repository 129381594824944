var Account = {
    initAccountPopup: function () {
        var self = this;
        $('.account-list.button')
            .popup({
                popup: $('.account-list.popup'),
                on: 'click',
                position: 'bottom right',
                onShow: function(){
                    self.resizeAccountPopup();
                }
            })
        ;
    },
    resizeAccountPopup: function(){
        var accountInnerWrapper = $('#account-inner-wrapper');
        accountInnerWrapper.css('max-height',$(window).height()-20);
    }

}