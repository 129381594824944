var UiMessage = {
    showMessage: function(element,message){
        var id = '#'+$(element).data('ui-message-id');
        if(typeof id !== 'undefined' && typeof message !== 'undefined' && message !== '' && $(id).hasClass('hidden')){
            $(id).html(message);
            $(id).transition('slide down');
            setTimeout(function(){
                $(id).transition('slide down');
            }, 3000);
        }
    }
};