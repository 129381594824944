var Modal = {
    ajaxLoad: function(url,callback){
        $('#page-modal .content').load(url,callback);
    },
    loadContent: function(content){
        Modal.clearModal();
        $('#page-modal .content').html(content);
    },
    setDefaultSize: function(){
        this.setLargeSize();
    },
    setTinySize: function(){
        this.removeSize();
        $('#page-modal').addClass('tiny');
    },
    setSmallSize: function(){
        this.removeSize();
        $('#page-modal').addClass('small');
    },
    setMediumSize: function(){
        this.removeSize();
        $('#page-modal').addClass('medium');
    },
    setLargeSize: function(){
        this.removeSize();
        $('#page-modal').addClass('large');
    },
    removeSize: function(size){
        var modal = $('#page-modal');
        modal.removeClass('tiny');
        modal.removeClass('large');
        modal.removeClass('medium');
        modal.removeClass('small');
    },
    showWaitingLayer: function(){
        Modal.clearModal();
        $('#page-modal .waiting-layer').show();
    },
    clearModal: function(){
        $('#page-modal .content').empty();
    },
    hideWaitingLayer: function(){
        $('#page-modal .waiting-layer').hide();
    },
    displayContent: function(){
        Modal.hideWaitingLayer();
    },
    showModal: function(){
        $('#page-modal').foundation("open");

        //$('#page-modal').css('top', '0px');

    },
    closeModal: function(){
        $('#page-modal').foundation("close");
        Modal.clearModal();
        Modal.showWaitingLayer();
    }
}