var Product = {
    slider: null,

    updateNotificationBlock: function (isajax) {
        var $prefix = isajax === true ? '#page-modal ' : '';

        let gravitaConainter = $($prefix + ' #gravita-product-variants-availability');
        let availabilityMessage = $($prefix + ' #gravita-current-availability-message');
        let variantInput = $($prefix + " input[name='gravita[variant-id]']");
        let classList = '';

        for (let element of document.querySelectorAll('select[name^="sylius_add_to_cart[cartItem][variant]"]')) {
            classList += element.value + ' ';
        }

        let domElement = document.getElementsByClassName(classList.trim());
        if (domElement.length > 0) {
            let data = domElement[0];
            if (!!+data.dataset.isAvailable !== true && data.dataset.availableFrom !== '') {
                availabilityMessage.text(data.dataset.availableMessage);
                variantInput.val(data.dataset.variantId);
                gravitaConainter.css('display', 'block');
            } else {
                gravitaConainter.css('display', 'none');
            }
        }
    },

    processAvailabilityForm: function (event) {
        event.stopPropagation();
        event.preventDefault();
        let form = event.target;
        form.classList.add('loading');
        let request = new Request(
            form.action, {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: new FormData(document.getElementById('gravita-availability-form')),
                credentials: 'include'
            }
        );

        fetch(request)
            .then(response => {
                return response.json();
            })
            .then(data => {
                let message = document.createElement('div');
                message.classList.add('ui', 'floating', 'message');
                message.appendChild(document.createTextNode(data.message));
                form.appendChild(message)
                form.elements['gravita[notification-email]'].value = '';

                setTimeout(() => {
                    form.removeChild(message);
                }, 3000);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => {
                form.classList.remove('loading');
            });

        return false;
    },

    initProductPage: function (isajax) {
        var $prefix = isajax === true ? '#page-modal ' : '';
        //Product.initModal($prefix+".modal-btn");
        Product.initReadmore($prefix + '.product-content-wrapper .product-show-details-wrapper .product-details .product-description');
        Product.initElevateZoom($prefix + ".product-img-zoom");
        $(document).on('click', $prefix + '.img-zoom-cmd', function (e) {
            e.preventDefault();
            $('#product-img-zoom').attr('src', $(this).data('img-path'));
            $('#product-img-zoom').data('zoom-image', $(this).data('img-path-large'));
            Product.initElevateZoom($prefix + '.product-img-zoom');
        });

        Product.initAddToCartForm(isajax);

        var $slider = AppSwiper.init($prefix + '#product-slider', {
            // Optional parameters
            slidesPerView: 2,
            slidesPerColumn: 2,
            spaceBetween: 10,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                },
                768: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                },
                640: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                }
            }
        });

        AppSwiper.init('#product-related-associations-slider', {
            slidesPerView: 5,
            spaceBetween: 30,
            slidesPerGroup: 5,
            loop: false,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    slidesPerGroup: 3,
                    slidesPerColumn: 1
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    slidesPerGroup: 2,
                    slidesPerColumn: 1
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    slidesPerGroup: 2,
                    slidesPerColumn: 1
                }
            }
        });

        Product.setSlider($slider);
    },
    initProductClickHistory: function (container) {
        if ($('body.brands-show').length === 0) {
            $(container).on('click', function () {
                var href = $(this).attr('data-history-href');
                window.history.replaceState({}, "Shop catalog", href);
                if (window.mobileAndTabletCheck()) {
                    window.history.scrollRestoration = "auto";
                } else {
                    window.history.scrollRestoration = "manual";
                }
            })
        }
    },
    initInfiniteScroll: function (container) {
        $(container).find('.pagination').hide();
        $(window).data('ajaxready', true);
        var deviceAgent = navigator.userAgent.toLowerCase();
        var agentID = deviceAgent.match(/(iphone|ipod|ipad)/);
        $(window).scroll(function () {
            if ($(window).data('ajaxready') == false) return;
            if (($(window).scrollTop() + $(window).height()) + 4000 > $(document).height()) {
                $(window).data('ajaxready', false);
                if ($(container).find('.pagination a.item[rel="next"]').length > 0) {
                    $(container).find('#loader').fadeIn(400);
                    var url = $(container).find('.pagination a.item[rel="next"]').attr('href');

                    $(container).find('.pagination').remove();
                    $.ajax({
                        url: url,
                        type: 'GET'
                    }).done(function (data) {

                        $(data).find('#products .cell').each(function () {
                            $(container).find('#products').append($(this));
                            Product.initProductClickHistory($(this).find('.product-box-href'));
                        });
                        $('.catalog-swiper-container').each(function (index, val) {
                            if (!$(val).hasClass('swip-initiated')) {
                                $(val).addClass('swip-initiated');
                                AppSwiper.init('#' + $(val).attr('id'), {
                                    // Optional parameters
                                    direction: 'horizontal',
                                    loop: true,
                                    effect: 'fade',

                                    // If we need pagination
                                    pagination: {
                                        el: '.swiper-pagination'
                                    },
                                    // Navigation arrows
                                    navigation: {
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev'
                                    },
                                });
                            }
                        });
                        $('.lazy').Lazy({
                            // your configuration goes here
                            scrollDirection: 'vertical',
                            effect: 'fadeIn',
                            visibleOnly: true,
                            onError: function (element) {}
                        });
                        $(container).find('#loader').after($(data).find('.pagination'));
                        $(container).find('#loader').fadeOut(400);

                        $(container).find('.flash-countdown-wrapper').each(function () {
                            Countdown.init(this);
                        });

                        $(window).data('ajaxready', true);
                        Product.initInfiniteScroll(container);
                    });
                }
            }
        });
    },

    setSlider: function ($slider) {
        this.slider = $slider;
    },


    getSlider: function () {
        return this.slider;
    },

    initAddToCartForm: function (isajax) {

        var $prefix = isajax === true ? '#page-modal ' : '';

        $($prefix + '.sylius-product-adding-to-cart').each(function () {
            $(this).variantPrices($prefix);
        });
        $($prefix + '.sylius-product-adding-to-cart').each(function () {
            $(this).variantImages();
        });
        $($prefix + '.sylius-product-adding-to-cart').each(function () {
            $(this).addToCart();
        });


        let availabilityForm = $($prefix + ' #gravita-availability-form');

        availabilityForm.on('submit', this.processAvailabilityForm);

        this.updateNotificationBlock(isajax);

        $($prefix + '.choice-toggler').unbind('click');
        $($prefix + '.choice-toggler').on('click', function () {
            var $optionId = $(this).data('id');
            var $productId = $(this).data('product-id');
            var $formuuid = $(this).data('formuuid');

            $('.' + $optionId + '_choice_toggler_' + $formuuid + '.' + $optionId + '_choice_toggler_product_' + $productId).removeClass('selected');
            $(this).addClass('selected');

            var val = $(this).data('value');
            var name = $(this).data('name');

            $('#' + $optionId + '.product-' + $productId + ' option[value=' + $(this).data('value') + ']').prop('selected', true).change();

            var $infoElement = $(".variant-pricing-wrapper-" + $formuuid + " .option-" + name + "-" + val + "option-product-" + $productId);
            var $price = $infoElement.data('value');
        });

        $($prefix + '.choice-toggler.toToggle').each(function (e) {
            $(this).trigger('click');
            $(this).removeClass('toToggle');
        });
    },


    handleImageChange: function ($formuuid, $productId, $images) {
        $images = JSON.parse($images);

        if (document.querySelector('.swiper-container-' + $productId) !== null) {
            var swiper = document.querySelector('.swiper-container-' + $productId).swiper;
            if (typeof swiper !== 'undefined') {
                swiper.removeAllSlides();
                for ($key in $images) {
                    swiper.appendSlide(
                        '<div class="swiper-slide"><a href="#" class="img-zoom-cmd" data-img-path="' +
                        $images[$key]['medium'] +
                        '" data-img-path-large="' +
                        $images[$key]['large'] +
                        '"><img src="' +
                        $images[$key]['medium'] +
                        '"></a></div>'
                    );
                }

                $(swiper.slides[0]).find('.img-zoom-cmd').trigger('click');
            }

        }
    },
    showModal: function (element) {
        var $element = $(element);
        $element.html('<i class="waiting-animation fa fa-spinner fa-pulse fa-fw margin-bottom"></i>');
        var container = $($element.data('aim') + ' .content');
        Modal.setLargeSize();
        Modal.showModal();
        Modal.showWaitingLayer();
        container.empty();
        container.load($element.data('ajax'), function () {
            $element.html($element.data('name'));
            Modal.displayContent();
            Product.initProductPage(true);
            Modal.showModal();
            $('#page-modal').foundation();
        });
    },
    /*,
    initModal: function(toggler){
        $(toggler).unbind('click');
        $(document).on('click',toggler,function(){
            $(this).html('<i class="waiting-animation fa fa-spinner fa-pulse fa-fw margin-bottom"></i>');
            var $self = $(this);
            var container = $($self.data('aim')+' .content');
            Modal.setLargeSize();
            Modal.showModal();
            Modal.showWaitingLayer();
            container.empty();
            container.load($self.data('ajax'),function(){
                $self.html($self.data('name'));
                Modal.displayContent();
                Product.initProductPage(true);
                Modal.showModal();
                $('#page-modal').foundation();
            });
        });
    },*/

    closeModal: function () {
        $('#page-modal').foundation("close");
    },

    initReadmore: function (target) {
        $(target).readmore({
            speed: 500,
            collapsedHeight: 50,
            moreLink: '<a class="readmore-btn readmore" href="#">Voir plus<br><i class="fa fa-caret-down" aria-hidden="true"></i></a>',
            lessLink: '<a class="readmore-btn readless" href="#">Voir moins <br><i class="fa fa-caret-up" aria-hidden="true"></i></a>'
        });
    },

    toggleTaxonMenu: function (menu, target) {
        menu.foundation('toggle', target);
    },

    initElevateZoom: function (id) {
        $(id).ezPlus({
            zoomType: 'inner',
            borderSize: 0,
            zoomContainerAppendTo: "body .pusher"
        });
    }
}