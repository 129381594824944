    var Checkout = {

        init: function(){
            Checkout.initModalCheckout(".edit-btn.checkout-modal-btn");
            Checkout.initToggler();
            Checkout.initAddress();
            Checkout.initFormUpdates();
        },
        initToggler: function(){
            $(document).on('click','#show-checkout-form-btn', function(){
                var id = $(this).data('toggle-show');

                if($(this).hasClass('active')){
                    $(this).removeClass('active');
                    $('#'+id).transition('slide down');
                } else {
                    $(this).addClass('active');
                    $('#'+id).transition('slide down');
                }

            });
        },
        showCheckoutForm: function(){
            var el = $('#show-checkout-form-btn');
            var id = el.data('toggle-show');

            if(!(el.hasClass('active'))){
                el.addClass('active');
                $('#'+id).transition('slide down');
            }
        },
        initAddress: function(){
            Checkout.addressBook("#sylius-shipping-address");
            Checkout.addressBook('#sylius-billing-address');
        },
        addressBook: function (el) {
            var element = $(el);
            var select = element.find('.address-book-select');
            var placeholder = element.find('.address-book-select .placeholder .text');
            select.dropdown({
                forceSelection: false,
                onChange: function (name, text, choice) {
                    placeholder.html(name);
                    var provinceCode = choice.data()['provinceCode'];
                    var provinceName = choice.data()['provinceName'];

                    $.each(element.find('input'), function (key, input) {
                        $(input).val('');
                    });
                    $.each(element.find('select'), function (key, select) {
                        $(select).val('');
                    });

                    $.each(choice.data(), function (property, value) {
                        if(property !== "countryCode"){
                            var field = findByName(property);

                            if (-1 !== property.indexOf('countryCode')) {
                                field.val(value).change();

                                var exists = setInterval(function () {
                                    var provinceCodeField = findByName('provinceCode');
                                    var provinceNameField = findByName('provinceName');

                                    if (0 !== provinceCodeField.length && ('' !== provinceCode || undefined != provinceCode)) {
                                        provinceCodeField.val(provinceCode);

                                        clearInterval(exists);
                                    } else if (0 !== provinceNameField.length && ('' !== provinceName || undefined != provinceName)) {
                                        provinceNameField.val(provinceName);

                                        clearInterval(exists);
                                    }
                                }, 100);
                            } else {
                                field.val(value);
                            }
                        }

                    });
                    $.each(choice.data(), function (property, value) {
                        if(property === "countryCode"){
                            var field = findByName(property);

                            if (-1 !== property.indexOf('countryCode')) {
                                field.val(value).change();

                                var exists = setInterval(function () {
                                    var provinceCodeField = findByName('provinceCode');
                                    var provinceNameField = findByName('provinceName');

                                    if (0 !== provinceCodeField.length && ('' !== provinceCode || undefined != provinceCode)) {
                                        provinceCodeField.val(provinceCode);

                                        clearInterval(exists);
                                    } else if (0 !== provinceNameField.length && ('' !== provinceName || undefined != provinceName)) {
                                        provinceNameField.val(provinceName);

                                        clearInterval(exists);
                                    }
                                }, 100);
                            } else {
                                field.val(value);
                            }
                        }

                    });
                }
            });

            var parseKey = function (key) {
                return key.replace(/(_\w)/g, function (words) {return words[1].toUpperCase()});
            };
            var findByName = function (name) {
                return element.find('[name*=' + parseKey(name) + ']');
            };
        },
        initModalCheckout: function(toggler){
            //$(toggler).unbind('click');
            $(document).on('click',toggler,function(){
                //$(this).html('<i class="fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom"></i>');
                var $self = $(this);
                var container = $($self.data('aim')+' .content');
                container.empty();
                Modal.setMediumSize();
                Modal.showModal();
                Modal.showWaitingLayer();
                container.load($self.data('ajax'),function(){
                    // $self.html($self.data('name'));

                    Modal.hideWaitingLayer();
                    $('.sylius-cart-remove-button').removeFromCart();
                    $('#sylius-summary-cart-form').updateCart();
                });
            });
        },
        initFormUpdates: function(){
            var form = $('form[name="sylius_checkout_onepage"]');
            form.find('#sylius_checkout_onepage_shippingAddress_countryCode').on('change',function(){
              if($(this).children("option:selected").val() !== '' && $(this).children("option:selected").val() != null) {
                Checkout.saveFormUpdates($(this).attr('name'),"#sylius_checkout_onepage_shippingAddress_countryCode");
              }

            });
           /* form.find('#sylius_checkout_onepage_shippingAddress_firstName').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'));
            });
            form.find('#sylius_checkout_onepage_shippingAddress_lastName').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'));
            });
            form.find('#sylius_checkout_onepage_shippingAddress_company').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'));
            });
            form.find('#sylius_checkout_onepage_shippingAddress_street').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'));
            });
            form.find('#sylius_checkout_onepage_shippingAddress_streetAdditional').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'));
            });
            form.find('#sylius_checkout_onepage_shippingAddress_provinceName').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'));
            });
            */
            form.find('#sylius_checkout_onepage_shippingAddress_city').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'),"#sylius_checkout_onepage_shippingAddress_city");
            });
            form.find('#sylius_checkout_onepage_shippingAddress_postcode').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'),"#sylius_checkout_onepage_shippingAddress_postcode");
            });
            form.find('#sylius_checkout_onepage_shippingAddress_phoneNumber').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'),"#sylius_checkout_onepage_shippingAddress_phoneNumber");
            });

            /* form.find('#sylius_checkout_onepage_billingAddress_countryCode').on('change',function(){
                 Checkout.saveFormUpdates($(this).attr('name'),"#sylius_checkout_onepage_billingAddress_countryCode");
             });
             form.find('#sylius_checkout_onepage_billingAddress_firstName').on('change',function(){
                 Checkout.saveFormUpdates($(this).attr('name'));
             });
             form.find('#sylius_checkout_onepage_billingAddress_lastName').on('change',function(){
                 Checkout.saveFormUpdates($(this).attr('name'));
             });
             form.find('#sylius_checkout_onepage_billingAddress_company').on('change',function(){
                 Checkout.saveFormUpdates($(this).attr('name'));
             });
             form.find('#sylius_checkout_onepage_billingAddress_street').on('change',function(){
                 Checkout.saveFormUpdates($(this).attr('name'));
             });
             form.find('#sylius_checkout_onepage_billingAddress_streetAdditional').on('change',function(){
                 Checkout.saveFormUpdates($(this).attr('name'));
             });
             form.find('#sylius_checkout_onepage_billingAddress_provinceName').on('change',function(){
                 Checkout.saveFormUpdates($(this).attr('name'));
             });
            form.find('#sylius_checkout_onepage_billingAddress_city').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'),"#sylius_checkout_onepage_billingAddress_city");
            });
            form.find('#sylius_checkout_onepage_billingAddress_postcode').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'),"#sylius_checkout_onepage_billingAddress_postcode");
            });
            form.find('#sylius_checkout_onepage_billingAddress_phoneNumber').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'),"#sylius_checkout_onepage_billingAddress_phoneNumber");
            });

*/
            form.find('.shipping-choice input[type=radio]').on('change',function(){
                Checkout.saveFormUpdates($(this).attr('name'),".shipping-choice input[type=radio]");
            });

            form.find('.pickup-list .pickup-item input[type=radio]').on('change',function(){
              Checkout.saveFormUpdates($(this).attr('name'),".shipping-choice input[type=radio]");
            });

        },
        saveFormUpdates: function(id,focusid){
            var form = $('form[name="sylius_checkout_onepage"]');
            form.attr('data-ajax-id',id);
            var url = form.attr('action');
            var d = {
                'update' : 1
            };
            $.ajax({
                type: "POST",
                url: url,
                data:
                form.serialize() + '&' + $.param(d)
                , // serializes the form's elements.
                success: function()
                {
                    $(".content-grid-container").load(window.location.href + " .content-grid-container .content-wrapper", function(){
                        if(form.attr('data-ajax-id') === id){
                            Checkout.initAddress();
                            var container = $('.content-grid-container');
                            container.find('.ui.checkbox').checkbox();
                            Checkout.initFormUpdates();
                            container.find('[data-toggles]').toggleElement();
                            Checkout.showCheckoutForm();
                            $(focusid).focus();
                            var checked = $('#sylius_checkout_onepage_differentBillingAddress').is(':checked');
                            if (checked === true){
                                $('#sylius_checkout_onepage_differentBillingAddress').parent().checkbox('check');
                            }
                            new pickupClass({url:$('.cart-form-wrapper').attr('data-pick-up-url') });
                        }
                    });
                }
            });

        },
        ajaxCartDelete : function(element){
            if(!$(element).hasClass('cart-ui-disabled')) {
                var $data = {
                    _csrf_token: $(element).data('csrf-token')
                };
                $(element).html('<i class="fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom"></i>');
                $(element)
                $.ajax({
                    url: $(element).data('action'),
                    type: "DELETE",
                    data: $data,
                    success: function (response) {

                        console.log(response);
                        //Cart.refreshCart(response);
                        $(element).parent().parent().parent().transition('slide down');
                        //Modal.hideWaitingLayer();
                        if(response.length > 0){
                            $('#page-modal .content').load($(element).data('ajax'),function(){
                                // $self.html($self.data('name'));
                                $('.sylius-cart-remove-button').removeFromCart();
                                $('#sylius-summary-cart-form').updateCart();
                            });

                        } else {
                            window.location.replace($(element).data('redirect'));
                        }
                    }

                });
            }
        }
    };
